import React, { useRef } from 'react';
import { Modal } from 'antd';
import styles from './index.module.scss';

const GridViewWindow:React.FC<{
  visible: boolean;
  onCancel: () => void;
  data: {
    subZoneName?: string;
    emptyCount?: string;
    berthTotal?: string;
    turnoverRate?: string;
    useRate?: string;
    parkTime?: string;
    concentration?: string;
    parkCount?: string;
    zoneName?: string;
    orderCount?: string;
    serviceCount?: string;
  }
}> = ({visible,onCancel,data}) => {

  return (
    <Modal
      className={styles.street_view}
      width={220}
      footer={null}
      visible={visible}
      onCancel={onCancel}
      closeIcon={<img src="/img/icon_close_circle.png" alt="" className={styles.window_close} />}
      maskStyle={{background: 'transparent'}}
    >
      <div style={{marginBottom: '8px',fontSize: '16px'}}>网格名称：{data.subZoneName}</div>
      <div className={styles.street_view_info}>网格区域类型：{data.zoneName}</div>
      {/* <div className={styles.street_view_info}>停车资源：{data.emptyCount} / {data.berthTotal}</div> */}

      {/* <div className={styles.street_view_info}>停车利用率：{data.useRate}</div>
      <div className={styles.street_view_info}>平均停车时长：{data.parkTime}</div> */}

      <div className={styles.street_view_info}>占用数：{data.serviceCount}</div>
      <div className={styles.street_view_info}>空闲数：{data.emptyCount}</div>
      <div className={styles.street_view_info}>停放次数：{data.orderCount}</div>
      <div className={styles.street_view_info}>停车周转率：{data.turnoverRate}</div>
      <div className={styles.street_view_info}>占用率：{((Number(data.serviceCount)  /(Number(data.serviceCount) + Number(data.emptyCount)))*100).toFixed(2)}%</div>
    </Modal>
  )
}

export default GridViewWindow;