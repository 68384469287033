import React, { useCallback, useEffect, useMemo, useState } from "react";
import Panel from "../index";
import style from "./style.module.scss";
import cn from "classnames";
import { Radar } from "@ant-design/charts";
import useZone from "@/services/data/useZone";
import { Spin } from "antd";
import { GetRatioData, GetRatioDataByType } from "@/services/api";
import { useRequest } from "ahooks";
import ImgSource from "@/assets/img/resource/zhouzhuan.png";
import { number } from "echarts";

import ReactEcharts from "echarts-for-react";
export interface ConvertProps {
  areaCode?: string;
}

const Convert: React.FC<ConvertProps> = (props) => {
  const [activeTab, changeTabIndex] = useState<string>("1");
  const [activeIndex, setActiveIndex] = useState<number>(0);
  //const { loading: TabLoading, data: TabData, error: ZoneError } = useZone()

  /*useEffect(() => {
    if (TabData?.success === true && TabLoading === false) {
      changeTabIndex(TabData.data[0].zoneId)
    }
  }, [TabData?.data, TabData?.success, TabLoading])
*/
  //let  TabData=[{zoneName:'医院',zoneId:'100'},{zoneName:'行业名',zoneId:'100'},{zoneName:'住宅',zoneId:'101'},{zoneName:'商业',zoneId:'102'},{zoneName:'办公',zoneId:'103'},{zoneName:'学校',zoneId:'104'},{zoneName:'其它',zoneId:'105'}]
  const radarService = useCallback(() => {
    if (activeTab === undefined) return Promise.resolve(undefined);
    // return GetRatioDataByType(props.areaCode);
    return GetRatioData();
  }, [activeTab, props.areaCode]);

  const { data: ratioData, loading, error: RadarError } = useRequest(
    radarService,
    {
      refreshDeps: [radarService],
      formatResult: (res) => res,
    }
  );

  // const TabList = useMemo(() => {
  //   // if (ratioData?.data === undefined) return [];
  //   // return ratioData?.data.map((item) => {
  //   //   return {
  //   //     zoneName: item.industryName,
  //   //     zoneId: item.industryId,
  //   //   };
  //   // });
  // }, [ratioData]);
  const radarData = useMemo(() => {
    // if (ratioData?.data === undefined) return [];

    // console.log(ratioData);
    // TODO:: 模拟数据
    const mookData = [1.7, 1.2, 1.3, 1.8, 2.1, 1.6, 1.9];
    // const datas = ratioData.data.map((item: any) => ({
    //   type: item.zoneName,
    //   data: item.turnover,
    // }));
    const radarDataLabels = [
      "住宅",
      "景区",
      "学校",
      "商业",
      "办公",
      "医院",
      "交通枢纽",
    ];
    return mookData.map((item, index) => ({
      data: item,
      type: radarDataLabels[index],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RadarProps = {
    data: radarData,
    xField: "type",
    yField: "data",
    padding: [40, 10, 40, 0],
    height: 200,
    meta: {
      data: {
        alias: "周转率",
        min: 0,
        max: 2.5,
        nice: true,
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
      label: {
        formatter(text: string, item: any, index: number) {
          return `${text}: ${radarData[index].data}%`;
        },
        style: {
          fill: "#ffffff",
        },
      },
      grid: {
        line: {
          style: {
            lineDash: null,
          },
        },
      },
    },
    yAxis: {
      grid: {
        line: {
          type: "line",
          style: {
            lineDash: null,
            stroke: "#7BD2FF",
          },
        },
        // alternateColor: 'rgba(123, 210, 255, 0.3)',
      },
      label: null,
    },
    area: {
      style: {
        fillOpacity: 0.85,
      },
    },
    tooltip: {
      crosshairs: {
        line: {
          style: {
            stroke: "#ffffff",
          },
        },
      },
    },
  };

  const RadarProps2 = {
    data: radarData,
    xField: "name",
    yField: "star",
    appendPadding: [0, 10, 0, 10],
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      // grid: {
      //   alternateColor: 'rgba(0, 0, 0, 0.04)',
      // },
    },
    // 开启辅助点
    point: {
      size: 2,
    },
    area: {},
  };

  // if (RadarError) {
  //   return (
  //     <Panel title="行业周转率">
  //       <div className="fetch_container" style={{ height: 278 }}>
  //         请求错误
  //       </div>
  //     </Panel>
  //   );
  // }

  return (
    <Panel title="业态车位周转率 ">
      <div className={style.container}>
        {/* <div className={style.tabs}>
          {TabList.map((i, index) => (
            <div
              className={cn(style.item, {
                [style.active]: activeTab === i.zoneId,
              })}
              key={`tab_${i.zoneId}`}
              onClick={() => {
                changeTabIndex(i.zoneId);
                setActiveIndex(index);
              }}
            >
              <p>{i.zoneName}</p>
            </div>
          ))}
        </div> */}
        {/* <div className={style.box}> */}
        {/* <div>
          {loading ? (
            <div className="fetch_container" style={{ height: 200 }}>
              <Spin />
            </div>
          ) : (
            <Radar {...RadarProps} />
          )}
        </div> */}
        <div className="fetch_container" style={{ height: 200 }}>
          <Radar {...RadarProps} />
        </div>
      </div>
    </Panel>
  );
};

export default Convert;
